<template>
  <ProductForm
    v-if="!isProductFetching"
    @saveDetail="saveCustomProduct"
    :productDetail="productDetail"
    :isDefault="isDefault"
    :update="true"
  />
</template>
  
  <script>
import ProductForm from "./CustomProductForm.vue";
import { mapActions } from "vuex";
import OrganizationHelper from "@/mixins/Organization";

export default {
  name: "EditCustomProduct",
  components: {
    ProductForm,
  },
  data: () => ({
    productDetail: {
      name: "",
      unit: "",
      price: 0,
      sku: null,
      applicableAreas: [],
      treatmentType: {},
      ingredients: "",
      treatmentTiming: "",
      timingOverride: false,
      brandId: "",
      description: "",
      aftercaretemplate: null,
    },
    isProductFetching: true,
    isDefault: false,
  }),
  mixins: [OrganizationHelper],
  methods: {
    ...mapActions("product", ["fetchProductDetailsV2", "updateCustomProduct"]),
    async saveCustomProduct(data, selectedTreatmentType, treatmentTypesOption) {
      try {
        this.$vs.loading();
        const payload = {
          name: data.name,
          unit: data.unit,
          applicableAreas: data.applicableAreas,
          treatmentType: selectedTreatmentType,
          organizationId: localStorage.getItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
          ),
          ingredients: data.ingredients,
          treatmentTiming: data.treatmentTiming,
          timingOverride: data.timingOverride,
          aftercaretemplate: data.aftercaretemplate,
        };

        const res = await this.updateCustomProduct({
          id: data._id,
          body: payload,
        });
        if (res.status == 200) {
          this.$vs.notify({
            title: "Product Add",
            text: "Product Detail added successfully",
            color: "success",
          });
          this.$vs.loading.close();
          this.$router.push({ name: this.getRoleRouteName("CustomProductsList") });
        }
      } catch (err) {
        this.$vs.notify({
          title: "Error",
          text: err.data.message,
          color: "danger",
        });
        this.$vs.loading.close();
      }
    },
    async getProductDetails() {
      try {
        this.$vs.loading();
        const { productId } = this.$route.params;
        const organizationId = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        );
        const res = await this.fetchProductDetailsV2({
          id: productId,
          organizationId,
        });
        if (res.status == 200) {
          this.productDetail = res.data.data;
        }
        this.isProductFetching = false;
        this.$vs.loading.close();
      } catch (err) {
        this.isProductFetching = false;
        this.$vs.loading.close();
        console.error(err);
      }
    },
  },
  created() {
    this.getProductDetails();
    this.isDefault = !this.$route.query.isCustomProduct;
  },
};
</script>
